import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";

// px2rem 自适应
import "lib-flexible";
// 引入element-ui当中的所有组件
import ElementUI from "element-ui";
//引入element-ui的所有样式
import "element-ui/lib/theme-chalk/index.css";
import "./style/index.less";
import "./style/main.css";
import "./style/responsive.css";
import "./style/global.css";
import "./style/style.css";
import "./style/coffee.css";
import $ from "jquery";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import echarts from "echarts";
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
Vue.prototype.$axios = axios; //把axios挂载到vue上
import "animate.css/animate.min.css";
Vue.use(Antd);

Vue.use(ElementUI);
new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app");
