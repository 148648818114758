import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/index",
    name: "home",
    component: HomeView
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    redirect: "/index"
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue")
  // },
  {
    path: "/zuobuApp",
    name: "zuobuApp",
    component: () => import("../views/zuobuApp.vue")
  },
  {
    path: "/zuobu",
    name: "zuobu",
    component: () => import("../views/zuobu.vue")
  },
  {
    path: "/zhidaiji_manage",
    name: "zhidaiji_manage",
    component: () => import("../views/zhidaiji_manage.vue")
  },
  {
    path: "/zhidaiji_num",
    name: "zhidaiji_num",
    component: () => import("../views/zhidaiji_num.vue")
  },
  {
    path: "/zhidaiji_storehouse",
    name: "zhidaiji_storehouse",
    component: () => import("../views/zhidaiji_storehouse.vue")
  },
  {
    path: "/yanbu",
    name: "zhidaiji_storehouse",
    component: () => import("../views/yanbu.vue")
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test.vue")
  },
  {
    path: "/case_details",
    name: "case_details",
    component: () => import("../views/case_details.vue")
  },
  {
    path: "/pibu_white",
    name: "pibu_white",
    component: () => import("../views/pibu_white.vue")
  },
  {
    path: "/pibu_storehouse",
    name: "pibu_storehouse",
    component: () => import("../views/pibu_storehouse.vue")
  },
  {
    path: "/pibu_qiandao",
    name: "pibu_qiandao",
    component: () => import("../views/pibu_qiandao.vue")
  },
  {
    path: "/pibu_num",
    name: "pibu_num",
    component: () => import("../views/pibu_num.vue")
  },
  {
    path: "/pibu_manage",
    name: "pibu_manage",
    component: () => import("../views/pibu_manage.vue")
  },
  {
    path: "/pibu_gyapp",
    name: "pibu_gyapp",
    component: () => import("../views/pibu_gyapp.vue")
  },
  {
    path: "/pibu_erp",
    name: "pibu_erp",
    component: () => import("../views/pibu_erp.vue")
  },
  {
    path: "/pibu_dajuan",
    name: "pibu_dajuan",
    component: () => import("../views/pibu_dajuan.vue")
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/news.vue")
  },
  {
    path: "/new_detail",
    name: "new_detail",
    component: () => import("../views/new_detail.vue")
  },
  {
    path: "/mission",
    name: "mission",
    component: () => import("../views/mission.vue")
  },
  {
    path: "/ma",
    name: "ma",
    component: () => import("../views/ma.vue")
  },
  {
    path: "/luomian_wms",
    name: "luomian_wms",
    component: () => import("../views/luomian_wms.vue")
  },
  {
    path: "/luomian_weight",
    name: "luomian_weight",
    component: () => import("../views/luomian_weight.vue")
  },
  {
    path: "/luobu_agv",
    name: "luobu_agv",
    component: () => import("../views/luobu_agv.vue")
  },
  {
    path: "/jobs",
    name: "jobs",
    component: () => import("../views/jobs.vue")
  },
  {
    path: "/iMES",
    name: "iMES",
    component: () => import("../views/iMES.vue")
  },
  {
    path: "/fangzhiquan",
    name: "fangzhiquan",
    component: () => import("../views/fangzhiquan.vue")
  },
  {
    path: "/course",
    name: "course",
    component: () => import("../views/course2.vue")
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about.vue")
  },
  {
    path: "/tianyingERP",
    name: "tianyingERP",
    component: () => import("../views/tianyingERP.vue")
  },
  {
    path: "/tianhuERP",
    name: "tianhuERP",
    component: () => import("../views/tianhuERP.vue")
  },
  {
    path: "/solution",
    name: "solution",
    component: () => import("../views/solution.vue")
  },
  {
    path: "/solution",
    name: "solution",
    component: () => import("../views/solution.vue")
  },
  {
    path: "/yingxiao",
    name: "yingxiao",
    component: () => import("../views/yingxiao.vue")
  },
  {
    path: "/tianfu",
    name: "yingxiao",
    component: () => import("../views/tianfu.vue")
  },
  {
    path: "/cangchu",
    name: "cangchu",
    component: () => import("../views/cangchu.vue")
  },
  {
    path: "/yanbu-solution",
    name: "cangchu",
    component: () => import("../views/yanbu-solution.vue")
  },
  {
    path: "/zhizao",
    name: "cangchu",
    component: () => import("../views/zhizao.vue")
  },
  {
    path: "/service",
    name: "service",
    component: () => import("../views/service.vue")
  },
  {
    path: "/yingjian",
    name: "yingjian",
    component: () => import("../views/yingjian.vue")
  }
];

const router = new VueRouter({
  routes
});
router.afterEach((to, from, next) => {
  window, scrollTo(0, 0);
});
export default router;
