import service from "./http.js";

export const getCasesPageList = (params) =>
  service({
    url: "/api/cases/getCasesPageList", // 请求地址
    method: "get", // 请求方式
    params: params // 请求参数
  });

export const getCasesById = (params) =>
  service({
    url: "/api/cases/getCasesById",
    method: "get",
    params: params
  });

export const getLatestNews = (params) =>
  service({
    url: "/api/news/getLatestNews",
    method: "get",
    params: params
  });
export const getHotNews = (params) =>
  service({
    url: "/api/news/getHotNews",
    method: "get",
    params: params
  });
export const getAdvertisementByType = (params) =>
  service({
    url: "/api/advertisement/getAdvertisementByType",
    method: "get",
    params: params
  });
export const getNewsPageList = (params) =>
  service({
    url: "/api/news/getNewsPageList",
    method: "get",
    params: params
  });
export const getParameter = (params) =>
  service({
    url: "/api/news/getParameter",
    method: "get",
    params: params
  });
export const getNewsById = (params) =>
  service({
    url: "/api/news/getNewsById",
    method: "get",
    params: params
  });
export const getRecruitmentPageList = (params) =>
  service({
    url: "/api/recruitment/getRecruitmentPageList",
    method: "get",
    params: params
  });
export const getSolutionList = (params) =>
  service({
    url: "/api/solution/getSolutionList",
    method: "get",
    params: params
  });
export const getSolutionById = (params) =>
  service({
    url: "/api/solution/getSolutionById",
    method: "get",
    params: params
  });
export const insertContact = (data) =>
  service({
    url: "/api/contact/insertContact",
    method: "post",
    data
  });
