<template>
  <div id="app">
    <a-affix :offset-top="top">
      <div class="menu-top" :style="topBannerNavBg">
        <div class="menu">
          <div class="logo">
            <img src="@/assets/images/logo.png" @click="to_index" />
          </div>
          <a-menu
            v-model="current"
            mode="horizontal"
            @click="menuClick"
            :style="topBannerNavBg"
          >
            <a-menu-item key="/index">公司首页</a-menu-item>
            <a-sub-menu key="/tianhuERP">
              <template #icon>
                <setting-outlined />
              </template>
              <template #title>核心产品</template>

              <a-menu-item key="/tianhuERP">智能ERP系统</a-menu-item>
              <a-menu-item key="/tianfu">织造MES系统</a-menu-item>
              <a-menu-item key="/yingjian">智能硬件选型</a-menu-item>
            </a-sub-menu>

            <a-sub-menu key="/cangchu">
              <template #icon>
                <setting-outlined />
              </template>
              <template #title>解决方案</template>
              <a-menu-item key="/zhizao">智能织造解决方案</a-menu-item>
              <a-menu-item key="/yanbu-solution">智能验布解决方案</a-menu-item>
              <a-menu-item key="/cangchu">智能仓储解决方案</a-menu-item>
              <a-menu-item key="/yingxiao">纺织营销解决方案</a-menu-item>
              <!-- <a-menu-item key="/pibu_white">联系我们</a-menu-item> -->
            </a-sub-menu>
            <a-menu-item key="/test">客户案例</a-menu-item>
            <a-menu-item key="/service">服务中心</a-menu-item>
            <a-sub-menu key="/about">
              <template #icon>
                <setting-outlined />
              </template>
              <template #title>关于巨细</template>
              <a-menu-item key="/about">关于我们</a-menu-item>
              <a-menu-item key="/course">发展历程</a-menu-item>
              <a-menu-item key="/jobs">加入我们</a-menu-item>
              <a-menu-item key="/news">新闻中心</a-menu-item>
              <!-- <a-menu-item key="/pibu_white">联系我们</a-menu-item> -->
            </a-sub-menu>
          </a-menu>
        </div>
      </div>
    </a-affix>

    <router-view />
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      current: [],
      topBannerNavBg: {
        backgroundColor: ""
      }
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
  },
  methods: {
    menuClick(item, key, keyPath) {
      this.$router.push({
        path: item.key
      });
    },

    to_index() {
      this.$router.push({
        path: "/index"
      });
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 设置背景颜色的透明度
      if (scrollTop) {
        this.topBannerNavBg.backgroundColor = "#fff"; // scrollTop + 多少根据自己的需求设置
        this.topBannerNavBg.color = "#333";
      } else if (scrollTop === 0) {
        this.topBannerNavBg.backgroundColor = "transparent"; // 设置回到顶部时，背景颜色为透明
        this.topBannerNavBg.color = "#fff";
      }
    },
    // 滚动之前重置
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    }
  }
};
</script>
<style lang="less">
#app {
  font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 0;
}
.menu-top {
  width: 100%;
  // padding-top: 10px;
  // padding-bottom: 5px;
  background-color: transparent;
  position: absolute;

  &:hover {
    background-color: #fff !important;
    .menu {
      // padding-top: 10px;
      background-color: #fff !important;
    }
    .ant-menu {
      color: #333 !important;
    }
  }
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.menu {
  position: relative;
  z-index: 999;
  width: 100%;
  padding: 20px 20px;
  padding-bottom: 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .ant-menu {
    display: flex;
    font-size: 20px;
    width: 760px;

    background-color: transparent !important;
    color: #fff;
  }

  .ant-menu-item-group-title {
    color: #333 !important;

    // position: relative;
    &:after {
      // position: absolute;
    }
  }
  .ant-menu-horizontal {
    border-bottom: none;
  }
}
.ant-menu-submenu {
}
.ant-menu-item {
  text-align: center !important;
}
.ant-menu-submenu-content {
  background: rgba(34, 34, 34, 0.53) !important;
  color: #ffffff !important;
  top: 190px !important;
  font-size: 16px;
  margin-top: 8px !important;
  margin-left: -18px !important;
}
.ant-menu-submenu-popup {
  // position: absolute !important;
  // top: 78px !important;

  // transform: translateX(-50%) !important;
}

.ant-menu-item-group-title {
  font-size: 18px !important;
  color: #333 !important;
  // margin-left: 12px;
  font-weight: 500;
  margin-bottom: 0px !important;
  text-align: center;
  &:after {
    // position: absolute;
    content: "" !important;
    width: 90% !important;
    bottom: 5px !important;
    height: 1px !important;
    left: 12px;
    // background-color: #dadada;
  }
}
.logo {
  width: 152px !important;
  height: 60px;
  img {
    width: 100% !important;
    height: 100%;
  }
}
.fixed {
  position: absolute;
  right: 0 !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}
.ant-menu-horizontal > .ant-menu-item-selected {
  border-bottom: none !important;
}
</style>
