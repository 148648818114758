<template>
  <div id="app">
    <div id="main"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import dataJson from "../assets/map";

export default {
  name: "app",
  data() {
    return {
      chinachart: {}
    };
  },
  methods: {
    initMap() {
      let myChart = echarts.init(document.getElementById("main"));
      let uploadDataUrl = dataJson;

      // 注册地图
      echarts.registerMap("china", uploadDataUrl);
      var data = [
        { name: "无锡", value: 11231 },
        { name: "苏州 ", value: 11231 },
        { name: "宿迁", value: 11231 },
        { name: "盐城 ", value: 241231 },
        { name: "徐州 ", value: 41231 },
        { name: "连云港 ", value: 11231 },
        { name: "南通", value: 11231 },
        { name: "青岛 ", value: 11231 },
        { name: "淄博", value: 11231 },
        { name: "滨州", value: 20231 },
        { name: "潍坊", value: 11231 },
        { name: "高密", value: 11231 },
        { name: "上饶 ", value: 11231 },
        { name: "九江", value: 11231 },
        { name: "抚州", value: 11231 },
        { name: "信阳", value: 11231 },
        { name: "周口", value: 11231 },
        { name: "蚌埠", value: 20031 },
        { name: "宿州", value: 20031 },
        { name: "芜湖", value: 20031 },
        { name: "宣城", value: 20231 },
        { name: "六安", value: 11231 },
        { name: "铜陵", value: 11231 },
        { name: "池州", value: 20001 },
        { name: "黄山", value: 20031 },
        { name: "合肥", value: 11231 },
        { name: "湖州", value: 11231 },
        { name: "嘉兴", value: 11231 },
        { name: "芜湖", value: 11231 },
        { name: "绍兴", value: 11231 },
        { name: "宁波", value: 11231 },
        { name: "阿克苏", value: 41231 },
        { name: "晋江", value: 41231 }
      ];
      var geoCoordMap = {
        无锡: [120.3, 31.57],
        宿迁市: [118.27, 33.98],
        连云港: [119.17, 34.6],
        宁波: [121.46, 29.74],
        信阳: [114.88, 32.08],
        晋江: [118.58, 24.9],
        福州: [119.18, 26.05],
        青岛: [120.14, 36.45],
        南通: [121.12, 32.19],
        扬州: [119.47, 32.74],
        绍兴: [120.64, 29.74],
        徐州: [117.52, 34.36],
        盐城: [120.18, 33.51],
        潍坊: [119.07, 36.55],
        湖州: [119.87, 30.74],
        芜湖: [118.13, 31.16],
        淄博: [118.05, 36.61],
        六安: [116.23, 31.66],
        上饶: [117.95, 28.42],
        周口: [114.88, 33.72],
        蚌埠: [117.33, 33.11],
        宣城: [118.85, 30.69],
        滨州: [117.84, 37.53],
        宿州: [117.21, 33.86],
        铜陵: [117.56, 30.88],
        蚌埠: [117.33, 33.11],
        九江: [115.91, 29.74],
        抚州: [116.91, 27.65],
        池州: [117.47, 30.286],
        贵阳: [106.71, 26.57],
        阿克苏: [82.97, 41.29]
      };

      var convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value)
            });
          }
        }
        return res;
      };

      let option = {
        legend: {
          bottom: "30",
          x: "center",
          textStyle: {
            color: "#fff"
          }
        },
        roam: false, //地图设置不可拖拽，固定的
        geo: [
          {
            show: true,
            map: "china",
            label: {
              normal: {
                show: false
              }
              //   emphasis: {
              //     show: false
              //   }
            },
            roam: false, //地图设置不可拖拽，固定的
            itemStyle: {
              normal: {
                areaColor: "#F6F6F6",
                borderWidth: 0,
                shadowColor: "#C5C5C5",
                shadowBlur: 30,
                shadowOffsetX: -5,
                shadowOffsetY: 10
              },
              emphasis: {
                areaColor: "rgba(58, 58, 58, 0.1)"
              }
            }
          }
        ],
        series: [
          {
            type: "map",
            map: "china",
            geoIndex: 1,
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: false,
                textStyle: {
                  color: "#fff"
                }
              }
            },
            roam: false,
            itemStyle: {
              normal: {
                areaColor: "#F6F6F6",
                borderColor: "#C5C5C5"
              },
              emphasis: {
                areaColor: "#EBEAEA"
              }
            }
          },
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            data: convertData(data),
            symbolSize: function (val) {
              return val[2] / 4000;
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke"
            },
            label: {
              normal: {
                formatter: "",
                position: "right",
                fontSize: "12",
                show: true
              }
            },
            itemStyle: {
              normal: {
                color: "#F2831E",
                shadowBlur: 10,
                shadowColor: "#F2831E"
              }
            }
          }
        ]
      };

      myChart.setOption(option);
    }
  },
  mounted() {
    this.initMap();
  }
};
</script>
<style lang="less" scoped>
#main {
  width: 900px;
  height: 700px;
}
</style>
