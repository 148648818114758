<template>
  <div class="home">
    <div class="contact">
      <!-- <div class="contact-c">
        <h3>联系我们，开启数字化智能化转型</h3>
        <span>方案资讯 | 预约演示 | 价格咨询 | 联系我们</span>
      </div> -->
      <!-- <img src="../assets/images/bottom.png" alt="" /> -->
      <div class="box">
        <div class="s-box" @click="visible_mes = true">
          <img src="../assets/images/phone.png" alt="" />
          <span>联系客服</span>
        </div>

        <div class="s-box" @click="visible = true">
          <img src="../assets/images/message.png" alt="" />
          <span> 留言联系</span>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="foot-c row">
        <div class="left col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="new_box">
            <div class="logo">
              <img src="../assets/images/foot-logo.png" alt="" />
            </div>
            <div class="phone">
              <div class="xiaoshou">
                <div class="title">
                  销售热线 <phone-outlined /> <span></span>
                </div>
                0512-6309-6333
              </div>
              <div class="zixun">
                <div class="title">咨询热线</div>
                0512-6309-6333
              </div>
            </div>
          </div>
          <div class="address">
            <div>
              <span>公司地址</span>
            </div>
            <div>常州总部：常州市武进区滆湖中路53号</div>
            <div>苏州地址：苏州市吴江区开平路金城大厦18楼</div>
            <!-- <div>苏州园区：苏州市工业园区星湖路328号创意产业园22-507室</div> -->
          </div>
        </div>
        <div class="right col-lg-5 col-md-5 col-sm-12 col-xs-12">
          <div>
            <div class="title">关于巨细</div>
            <ul>
              <li @click="to_pages('/about')">企业介绍</li>
              <li @click="to_pages('/course')">发展历程</li>
              <li @click="to_pages('/jobs')">人才招聘</li>
            </ul>
          </div>
          <div>
            <div class="title">产品介绍</div>
            <ul>
              <li @click="to_pages('/tianfu')">织造MES系统</li>
              <li @click="to_pages('/tianhuERP')">智能织造ERP</li>
              <li @click="to_pages('/yanbu-solution')">智能验布解决方案</li>
              <li @click="to_pages('/yingxiao')">纺织营销解决方案</li>
              <!-- <li @click="to_pages('/zuobuApp')">做布面料跟单</li> -->
            </ul>
          </div>
          <div>
            <div class="title">关注巨细</div>
            <img src="../assets/images/ma.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="beian">
      <a href="https://beian.miit.gov.cn"
        >Copyright © 2020 JUXI | All rights reserved.
        <span>苏ICP备14008913-1号</span></a
      >
    </div>

    <a-modal
      v-model="visible"
      title="联系信息"
      @ok="handleOk"
      ok-text="立即提交"
      cancel-text="取消"
      :width="400"
      style="top: 400px"
      @cancel="cancel"
    >
      <a-form
        :form="formState"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 18 }"
        ref="form"
      >
        <a-form-item label="姓名">
          <a-input
            placeholder="请输入您的姓名"
            v-decorator="[
              'Name',
              { rules: [{ required: true, message: '请输入您的姓名' }] }
            ]"
          />
        </a-form-item>

        <a-form-item label="手机号">
          <a-input
            placeholder="请输入您的手机号"
            v-decorator="[
              'Tel',
              {
                rules: [
                  { required: true, message: '请输入您的手机号' },
                  {
                    pattern:
                      /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
                    message: '请输入正确的手机号码'
                  }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-form-item label="公司名">
          <a-input
            placeholder="请输入公司全称"
            v-decorator="[
              'Company',
              { rules: [{ required: true, message: '请输入您的公司名' }] }
            ]"
          />
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea
            placeholder="请填写备注"
            v-decorator="[
              'Remark',
              { rules: [{ required: false, message: '请填写备注' }] }
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-model="visible_mes" :footer="null" :width="240">
      <div class="modal-c">
        <div class="title">添加客服企业微信</div>
        <img src="../assets/images/wechat.png" alt="" />
        <div class="num">0512-63096333</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { insertContact } from "@/api/index";
export default {
  name: "footer",
  components: {},
  data() {
    return {
      visible: false,
      visible_mes: false,

      formState: this.$form.createForm(this),
      rules: {
        name: [{ required: true, message: "请输入您的姓名!" }],
        Tel: [
          {
            required: true,
            message: "请输入您的手机号!",
            pattern:
              /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
          }
        ],
        company: [{ required: true, message: "请输入公司全称!" }]
      }
    };
  },
  methods: {
    to_pages(page) {
      this.$router.push({
        path: page
      });
    },
    handleOk() {
      let that = this;
      var form = {
        Name: that.formState.getFieldValue("Name"),
        Tel: that.formState.getFieldValue("Tel"),
        Company: that.formState.getFieldValue("Company "),
        Remark: that.formState.getFieldValue("Remark")
      };

      that.formState.validateFields((err, values) => {
        insertContact(form).then((res) => {
          that.visible = false;
          if (err) {
            that.visible = true;
            // that.formState.resetFields();
          }
          // that.formState.resetFields();
        });
      });
    },
    cancel() {
      this.visible = false;
      this.formState.resetFields();
    }
  }
};
</script>
<style lang="less" scoped>
.home {
  .contact {
    height: 224px;
    position: relative;
    background: url("../assets/images/bottom.png") no-repeat;
    background-size: contain;
    padding-top: 50px;
    .box {
      position: absolute;
      right: 0;
      top: 30px;
      // width: 100px;
      .s-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #0c85f2;
        font-size: 12px;
        color: #ffffff;
        height: 65px;
        width: 65px;
        padding-top: 10px;
        border-radius: 5px 5px 5px 5px;
        margin-bottom: 10px;
        cursor: pointer;
        img {
          width: 22px;
          height: 22px;
          margin-bottom: 3px;
        }
        span {
          font-size: 12px;
        }
      }
    }

    .contact-c {
      color: #fff;
      font-size: 16px;
      h3 {
        text-align: center;
        color: #fff;
        font-weight: 600;
        font-size: 28px;
      }
    }
  }
  .foot {
    // height: 360px;

    background: #060a2f;
    color: #fff;
    padding-top: 30px;

    .foot-c {
      width: 1620px;
      margin: 0 auto;

      color: #e8e8e8;
      // display: flex;
      text-align: left;
      .left {
        padding-right: 50px;
        margin-bottom: 20px;
        // border-right: 2px solid #ccc;
        .logo {
          width: 154px;
          height: 154px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .new_box {
          display: flex;
        }
        .address {
          margin-top: 10px;
          text-align: left;
          margin-left: 24px;
          font-size: 16px;
          color: #cacaca; // div {
          //   line-height: 46px;
          // }
          & > div {
            height: 28px;
          }
        }
      }
      .right {
        border-left: 1px solid #373953;
        padding-left: 70px;
        flex: 60%;
        display: flex;
        justify-content: space-between;
        .title {
          color: #e8e8e8;

          font-size: 22px;
          margin-bottom: 30px;
          margin-top: 15px;
        }
        // & > div {
        //   width: 30%;
        // }
        ul {
          padding: 0;
        }
        li {
          color: #cacaca;

          font-size: 16px;
          height: 32px;
          text-align: left;
          cursor: pointer;
          // line-height: 30px;
          img {
            width: 100px;
            height: 100px;
          }
        }
      }
      .phone {
        // display: flex;
        margin-top: 10px;
        font-size: 18px;
        margin-left: 50px;
        & > div {
          margin-right: 42px;
          margin-bottom: 20px;
        }
        .title {
          font-size: 16px;
        }
      }
    }
  }
  .beian {
    color: #afafaf;
    background: #060a2f;
    text-align: center;
    height: 80px;
    padding-top: 15px;
    line-height: 30px;
    font-size: 16px;
    padding-top: 20px;
    // border-top: 1px solid #ccc;
    a {
      color: #afafaf;
    }
    // span {
    //   color: #393939;
    // }
  }
}
::v-deep .ant-modal-title {
  font-size: 16px;
  color: #333333;
  font-weight: 500;
  margin-left: 15px;
}
.ant-form-item {
  margin-bottom: 12px;
}
.modal-c {
  margin: 0 auto;
  width: 140px;
  .title {
    font-size: 16px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 20px;
  }
  .num {
    color: #333333;
    font-weight: 400;
    font-size: 18px;
    margin-top: 8px;
  }
  img {
    width: 130px;
    height: 130px;
  }
}
</style>
