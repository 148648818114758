import axios from "axios";

const service = axios.create({
  baseURL: "http://www.szjuxi.cn/",
  timeout: 5000
});
service.interceptors.request.use((config) => {
  return config;
});

// service.interceptors.response.use(
//   (response) => {
//     Message.closeAll();
//     const res = response.data;
//     if (res.code == 400) {
//       Message.error("请求失败");
//     }
//   },
//   (error) => {
//     Message({
//       message: error.message,
//       type: "error",
//       duration: 5 * 1000
//     });
//     return Promise.reject(error);
//   }
// );

export default service;
