<template>
  <div class="home">
    <div class="banner">
      <img :src="advertisement[0].PicPathUrl" alt="" />

      <!-- <div class="new-box">
        <div class="flex-box">
          <img src="../assets/images/home/home05.png" alt="" />
          <p>智能化生产</p>
        </div>
        <div class="flex-box">
          <img src="../assets/images/home/home01.png" alt="" />
          <p>坯布织造</p>
        </div>
        <div class="flex-box">
          <img src="../assets/images/home/home02.png" alt="" />
          <p>纺织贸易</p>
        </div>
        <div class="flex-box">
          <img src="../assets/images/home/home04.png" alt="" />
          <p>棉纺织造</p>
        </div>

        <div class="flex-box">
          <img src="../assets/images/home/home03.png" alt="" />
          <p>线带织造</p>
        </div>
      </div> -->
    </div>

    <div class="new-juxi">
      <div class="row" style="margin: 0">
        <div class="left col-lg-6 col-md-6 col-sm-11 col-xs-11">
          <div class="title">巨细科技 专注纺织行业</div>
          <div>
            巨细科技以“纺织智改数转”为核心，提供纺织智能工厂、精益生产与纺织品营销等整体解决方案的高科技企业。
            <br />
            <br />
            巨细深耕纺织行业十余年，秉承“质量第一、服务至上”的理念赢得新老客户信赖，逐渐发展成口碑与实力并存的纺织智能制造车间整体方案解决商。
          </div>
          <!-- <div class="button" @click="to_about">Learn More</div> -->
        </div>
        <div class="right col-lg-5 col-md-5 col-sm-11 col-xs-11">
          <a-carousel autoplay>
            <div><img src="../assets/images/home/01.jpg" alt="" /></div>
            <div><img src="../assets/images/home/02.jpg" alt="" /></div>
            <div><img src="../assets/images/home/03.jpg" alt="" /></div>
          </a-carousel>
        </div>
      </div>
      <div class="center-bg"></div>
    </div>
    <div class="new-type">
      <div class="new-type-c">
        <div class="title">数字化时代 新生产方式</div>
        <div class="box">
          <div class="content">
            <div class="title-f">高效管理</div>
            <div class="visible">
              <p>
                通过织机监控及大数据技术，优化纺织设备、整合行业资源，抢占“云纺织”先机，将传统织造升级为全流程信息化、数据化管理。
              </p>
            </div>
          </div>
          <div class="content">
            <div class="title-f">智能生产</div>
            <div class="visible">
              <p>
                利用前沿最新技术，自主研发纺织智能设备，帮助企业在前道、织造、检验、仓储等环节实现高效率、智能化、低成本生产模式，帮助企业降本增效。
              </p>
            </div>
          </div>
          <div class="content">
            <div class="title-f">纺织贸易</div>
            <div class="visible">
              <p>
                面向纺织贸易、面料生产领域，帮助”大贸易，小工厂”型企业，解决在样品管理、新品开发、面料生产跟单方面的行业痛点，帮助贸易企业提升交付能力，提高产品品质。
              </p>
            </div>
          </div>
          <div class="content">
            <div class="title-f">智能仓储</div>
            <div class="visible">
              <p>
                通过WMS仓储管理系统和仓库手持PDA、搬运AGV、智能化立体仓库相结合，有效管控仓库工作各环节，为企业带来智能、便捷、安全、高效的仓库管理方式。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="home-industry">
      <div class="wow fadeIn s_title_h1" data-wow-delay="0.3s">
        <h1 class="section-title">深耕纺织行业 多场景解决方案</h1>
      </div>

      <!-- home-box-->
      <div class="home-box clearfix">
        <ul
          class="tab container_h clearfix"
          id="tab01"
          @mousemove="changeTab()"
        >
          <li class="active tab01-box">
            <div class="picbox">
              <figure class="pic pic01">
                <img src="../assets/images/home-icon01.png" class="vcenter" />
              </figure>
              <figure class="pic pic02">
                <img src="../assets/images/home-icon01.png" class="vcenter" />
              </figure>
            </div>
            <p class="title">智能织造ERP</p>
          </li>
          <li class="tab01-box">
            <div class="picbox">
              <figure class="pic pic01">
                <img src="../assets/images/home-icon03.png" class="vcenter" />
              </figure>
              <figure class="pic pic02">
                <img src="../assets/images/home-icon03.png" class="vcenter" />
              </figure>
            </div>
            <p class="title">智能验布</p>
          </li>
          <li class="tab01-box">
            <div class="picbox">
              <figure class="pic pic01">
                <img src="../assets/images/home-icon05.png" class="vcenter" />
              </figure>
              <figure class="pic pic02">
                <img src="../assets/images/home-icon05.png" class="vcenter" />
              </figure>
            </div>
            <p class="title">AGV落布</p>
          </li>
          <li class="tab01-box">
            <div class="picbox">
              <figure class="pic pic01">
                <img src="../assets/images/home-icon06.png" class="vcenter" />
              </figure>
              <figure class="pic pic02">
                <img src="../assets/images/home-icon06.png" class="vcenter" />
              </figure>
            </div>
            <p class="title">纺织圈</p>
          </li>
        </ul>
        <div class="tabbg" id="tabbg01">
          <div class="content hbg01" style="display: block">
            <ul class="container_h">
              <li class="li01">
                <h3>
                  <a>智能织造ERP</a>
                </h3>
                <p>
                  巨细天狐™智能制作ERP系统涵盖纺织全流程，可根据合同订单自动下推生产计划、计算原料消耗、进行人员排班等，生产数据实时监控并生成报表，让您轻松实现精细化智能化纺织生产管理。
                </p>
                <a @click="to_page('/zhizao')" class="more">了解更多</a>
              </li>
              <li class="li01">
                <h3>合作企业</h3>
                <p>中吴丝织、中天喷织、乐恒织造、金博得、鑫旺升等</p>
                <h3>核心技术</h3>
                <p>纺织全流程、一体化</p>
              </li>
            </ul>
          </div>

          <div class="content hbg01">
            <ul class="container_h">
              <li class="li01">
                <h3>
                  <a>智能验布</a>
                </h3>
                <p>
                  基于人工智能和视觉识别技术，通过高速工业摄像机实现自动验布，全方位提升纺织企业质检质量与效率，缓解人工目检精度难保证等问题，将人工成本降低50%-70%，质检效率提升50%，数据汇总及分析时间降低80%。
                </p>
                <a @click="to_page('/yanbu-solution')" class="more">了解更多</a>
              </li>
              <li class="li01">
                <h3>合作企业</h3>
                <p>福华世家、苏州南华、龙州纺织、华拓、智帛创纺织等</p>
                <h3>核心技术</h3>
                <p>AI视觉瑕疵检测技术、多智能体协同学习技术</p>
              </li>
            </ul>
          </div>

          <!--AGV落布-->
          <div class="content hbg01">
            <ul class="container_h">
              <li class="li01">
                <h3>
                  <a>AGV落布</a>
                </h3>
                <p>
                  是巨细科技针对纺织车间落布工作强度高、布匹存放混乱、人力成本过高等一系列问题而研发的智能化车间落布解决方案。主要由落布调度系统、落布AGV小车、小车充电桩和落布存储站组成。
                </p>
                <a @click="to_page('/zhizao')" class="more">了解更多</a>
              </li>
              <li class="li01">
                <h3>合作企业</h3>
                <p>恒力纺织、福华世家、永常织造</p>
                <h3>核心技术</h3>
                <p>磁导航技术、AGV智能调度</p>
              </li>
            </ul>
          </div>
          <!-- /AGV落布-->
          <!-- 纺织圈-->
          <div class="content hbg01">
            <ul class="container_h">
              <li class="li01">
                <h3>
                  <a>纺织圈</a>
                </h3>
                <p>
                  是一款面向纺织织造企业的营销推广工具，旨在帮助企业线上线下多场景快速获客、客户跟踪、客户管理，同时为企业提供场偏推广、采购订单、在线拿样、订单管理、业务员管理等诸多功能。
                </p>
                <a @click="to_page('/yingxiao')" class="more">了解更多</a>
              </li>
              <li class="li01">
                <h3>合作企业</h3>
                <p>所有纺织圈用户</p>
                <h3>核心技术</h3>
                <p>上下游资源整合、二维码应用</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /home-box-->
      <!-- home-box-->

      <!-- /home-box-->
      <!-- 
<p align="center">
	<a href="#" id="toggle" target="_blank">查看全部</a> 
</p>
-->
    </section>

    <!-- /home-box-->
    <!-- <div class="choice">
      <div class="title">5000+纺织企业，60万台织机的选择</div>
      <a-carousel autoplay>
        <div class="choice-c">
          <p>
            根据智能创造价值，最终目的是为客户提供更有价值的服务，致力于纺织企业的转型升级
            <br />「事无巨细，人有得失，得之小心，失之费力」
          </p>
          <div class="bottom">
            <img src="../assets/images/tx01.jpg" alt="" />
            <span>巨细科技/创始人/葛金宝</span>
          </div>
        </div>
        <div class="choice-c">
          <p>
            智能化使得车间信息更为透明、实时，再加上与客户的协作，使得生产计划明确、物料掌控度高，库存有效管理，提高了客户响应速度。不仅如此，订单准交率，工单准时完工率，账料准确率等关键运营指标都有了大幅提升。
          </p>
          <div class="bottom">
            <img src="../assets/images/tx02.jpg" alt="" />
            <span>巨细科技/总经理</span>
          </div>
        </div>
      </a-carousel>
    </div> -->

    <div class="new-juxi new-map">
      <div class="row" style="margin: 0">
        <div class="left col-lg-5 col-md-5 col-sm-11 col-xs-11">
          <div class="title">服务网络</div>
          <div>
            巨细科技拥有十余年的纺织行业经验，以“专业化产品和技术”为核心竞争力，以“个性化服务”为亮点，将根据客户需求和现场情况进行软件配置、硬件布局等定制化服务，为企业提供“智改数转”整体解决方案。
          </div>
          <div class="num">
            <div>
              <div><span>60</span>万+</div>
              <div>织机监控</div>
            </div>
            <div>
              <div><span>5000</span>+</div>
              <div>智改数转</div>
            </div>
          </div>
          <div class="bottom">
            <div>
              <img src="../assets/images/home/home13.png" alt="" />
              <div>专业实施</div>
            </div>
            <div>
              <img src="../assets/images/home/home14.png" alt="" />
              <div>灵活配置</div>
            </div>
            <div>
              <img src="../assets/images/home/home11.png" alt="" />
              <div>实时响应</div>
            </div>
            <div>
              <img src="../assets/images/home/home12.png" alt="" />
              <div>迭代更新</div>
            </div>
          </div>
        </div>
        <div class="right col-lg-6 col-md-6 col-sm-11 col-xs-11">
          <China-Map />
        </div>
      </div>
    </div>

    <!--新闻开始-->
    <!-- 
    <section class="home-news service2-intro" id="blog">
      <div class="container-w">
        <div class="row">
          <div class="col-md-12 wow fadeIn s_title_h1" data-wow-delay="0.3s">
            <h1 class="section-title">新闻资讯</h1>
          </div>
          <div class="col-md-6 service2-single-service wow fadeIn">
            <div
              class="lates-news"
              data-wow-delay="0.3s"
              @click="redirectURL(news.ID)"
            >
              <p>{{ news.CreateTime }}</p>
              <h2>{{ news.Title }}</h2>
              <div>{{ news.Summary }}</div>
              <img :src="news.ThumbnailUrl[0]" alt="" />
            </div>
          </div>
          <div class="col-md-6 service2-single-service wow fadeIn">
            <div
              class="lates-news"
              data-wow-delay="0.3s"
              v-for="(item, index) in tabList"
              @click="redirectURL(item.ID)"
              :key="item.ID"
            >
              <p>{{ item.CreateTime }}</p>
              <h2>{{ item.Title }}</h2>
              <div>{{ item.Summary }}</div>
            </div>
          </div>
        </div>

        <div class="more">
          <a @click="to_news">更多企业动态</a>
        </div>
      </div>
    </section> -->
    <section class="home-news service2-intro">
      <div class="container-w">
        <div class="row">
          <div class="col-md-12 wow fadeIn s_title_h1" data-wow-delay="0.3s">
            <h1 class="section-title">新闻资讯</h1>
          </div>
        </div>
        <div class="row">
          <div
            v-for="(item, index) in tabList"
            :key="index"
            class="col-lg-3 col-md-3 col-sm-11 col-xs-11"
            style="backgroundcolor: #fff"
          >
            <div class="news-box-home" @click="redirectURL(item.ID)">
              <img :src="item.ThumbnailUrl[0]" alt="" class="news-image" />
              <div class="bottom">
                <p>{{ item.CreateTime }}</p>
                <div>{{ item.Summary }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="more">
          <a @click="to_news">更多企业动态</a>
        </div>
      </div>
    </section>

    <FooterBottom></FooterBottom>
  </div>
</template>

<script>
// @ is an alias to /src
import FooterBottom from "@/components/footerBottom.vue";
import ChinaMap from "./map.vue";
import $ from "jquery";
import { getLatestNews, getAdvertisementByType } from "@/api/index";

export default {
  name: "HomeView",
  components: {
    FooterBottom,
    ChinaMap
  },
  data() {
    return {
      advertisement: [],
      tabList: [],
      news: {}
    };
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.changeTab();
  },
  methods: {
    changeTab() {
      $(document).ready(function () {
        $("#tab01 .tab01-box").hover(function () {
          $("#tabbg01 .content").eq($(this).index()).show().siblings().hide();
          $(this).addClass("active").siblings().removeClass("active");
          $("#tabbg02 .content").hide();
          $("#tabbg03 .content").hide();
          $("#tabbg04 .content").hide();

          $("#tab02 li").removeClass("active");
          $("#tab03 li").removeClass("active");
          $("#tab04 li").removeClass("active");
        });

        $("#tab02 li").hover(function () {
          $("#tabbg02 .content").eq($(this).index()).show().siblings().hide();
          $(this).addClass("active").siblings().removeClass("active");
          $("#tabbg01 .content").hide();
          $("#tabbg03 .content").hide();
          $("#tabbg04 .content").hide();
          $("#tab01 li").removeClass("active");
          $("#tab03 li").removeClass("active");
          $("#tab04 li").removeClass("active");
        });

        $("#tab03 li").hover(function () {
          $("#tabbg03 .content").eq($(this).index()).show().siblings().hide();
          $(this).addClass("active").siblings().removeClass("active");
          $("#tabbg01 .content").hide();
          $("#tabbg02 .content").hide();
          $("#tabbg04 .content").hide();
          $("#tab01 li").removeClass("active");
          $("#tab02 li").removeClass("active");
          $("#tab04 li").removeClass("active");
        });

        $("#tab04 li").hover(function () {
          $("#tabbg04 .content").eq($(this).index()).show().siblings().hide();
          $(this).addClass("active").siblings().removeClass("active");
          $("#tabbg01 .content").hide();
          $("#tabbg02 .content").hide();
          $("#tabbg03 .content").hide();
          $("#tab01 li").removeClass("active");
          $("#tab02 li").removeClass("active");
          $("#tab03 li").removeClass("active");
        });
      });
    },
    to_page(key) {
      this.$router.push({
        path: key
      });
    },
    loadData() {
      getAdvertisementByType({ Type: "0" }).then((res) => {
        this.advertisement = res.data.data;
      });
      getLatestNews().then((res) => {
        this.tabList = res.data.data;
        this.news = res.data.data[0];

        this.tabList = this.tabList.slice(0, 4);
      });
    },
    redirectURL(ID) {
      this.$router.push({ path: "/new_detail", query: { ID: ID } });
    },
    to_news() {
      this.$router.push({ path: "/news" });
    },
    to_about() {
      this.$router.push({ path: "/about" });
    }
  }
};
</script>
<style lang="less" scoped>
.home {
  margin-top: -90px;
}
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  // height: 92vh;
  // line-height: 160px;
  // background: #364d79;
  overflow: hidden;
}
.banner {
  // margin-bottom: 30px;
  position: relative;
  height: 800px;
  img {
    width: 100%;
  }
  // height: 100vh;
}
.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
.home-box {
  display: flex;
  //width: 1800px;
  // margin: 30px auto;
  flex-wrap: wrap;
  main {
    flex: 25%;
    position: relative;
  }

  .item {
    img {
      margin-bottom: 20px;
    }
    p {
      font-size: 18px;
      color: #82a1bf;
    }
  }
}

.hide {
  display: none !important;
}

.container_h {
  width: 1800px;
  margin: 0 auto !important;
  padding: 0 10px;
}
.choice {
  .ant-carousel :deep(.slick-slide) {
    text-align: center;
    // height: 400px;
    // line-height: 160px;
    background: rgba(255, 255, 255, 0.39);
    overflow: hidden;
  }

  .title {
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    margin-top: 60px;
  }
  ::v-deep .ant-carousel {
    .choice-c {
      width: 1800px;
      margin: 0 auto;
      p {
        font-size: 20px;
        color: #555555;
        line-height: 40px;
        width: 800px;
        margin: 70px auto 0;
      }
      .bottom {
        display: flex;
        width: 400px;
        margin: 0 auto;
        align-items: center;
        color: #333333;
        font-weight: 500;
        font-size: 18px;
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-top: 30px;
          margin-right: 30px;
        }
        span {
          margin-top: 40px;
        }
      }
    }
  }
}
.home-news {
  .container {
    width: 1800px;
    margin: 0 auto;
  }
}

/*数字化转型底圖*/
.home-intell li .bg {
  background: rgba(0, 90, 255, 0.8) !important;
}
/* 展开区块上方箭头 */
.home-industry .home-box .tab li::before {
  border-bottom: 15px solid #0c5beb !important;
}
/* 展开区块底图 */
.home-box .tabbg .content {
  // background-size: 100%;
  height: 380px !important;
  background-size: cover;
  text-align: left;
}
.tabbg {
  width: 100%;
  margin-top: 30px;
  background: url(../assets/images/home-bg-2.png) no-repeat top center;
  background-size: 100%;
}
// .home-box .tabbg .content {
// }

.picbox {
  width: 130px;
  height: 130px !important;
  margin-bottom: 15px;
  img {
    width: 100%;
    height: 100%;
  }
}
p.title {
  width: 130px;
  text-align: center;
}

.new-box {
  position: absolute;
  width: 1800px;
  // height: 280px;
  margin: 20px auto;
  bottom: 9%;
  // margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  background-color: #fff;
  z-index: 1;
  align-items: center;
  .flex-box {
    flex: 1;
    width: 280px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    &:hover {
      background-color: #0da5ea;
      p {
        color: #fff;
      }
    }
    img {
      width: 143px;
      height: 138px;
    }
    p {
      font-size: 18px;
      color: #7c7c7c;
    }
  }
}

.ant-carousel {
  // height: 720px;
  img {
    width: 100%;
    // height: 720px;
  }
}

.new-juxi {
  position: relative;
  background-color: #f7f7f7;
  padding: 50px 30px;
  .left {
    position: relative;
    // width: 42%;
    text-align: left;
    color: #797979;
    font-size: 20px;
    // line-height: 30px;
    margin-right: 60px;
    .title {
      color: #3c3c49;
      font-weight: 600;
      font-size: 40px;
      margin-bottom: 50px;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        bottom: -24px;
        left: 0;
        width: 40px;
        height: 2px;
        background-color: #3c3c49;
      }
    }
    .button {
      margin-top: 50px;

      color: #086cbd;
      border: 2px solid #086cbd;
      margin-bottom: 30px;
      width: 180px;
      height: 46px;
      line-height: 40px;
      border-radius: 25px;
      text-align: center;
      cursor: pointer;
    }
  }
  .right {
    // position: relative;
    // width: 700px;
    // height: 410px;
    z-index: 1;
    .image {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .center-bg {
    position: absolute;
    right: 0;
    top: 0;
    width: 35%;
    height: 100%;
    z-index: 0;
    background-color: #2156cc;
  }
  .center {
    display: flex;
    width: 1800px;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  .num {
    display: flex;
    // justify-content: space-between;
    font-size: 20px;
    color: #202020;
    margin: 80px auto;
    & > div {
      width: 300px;
    }
    span {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #202020;
    margin-bottom: 60px;
    align-items: center;
    & > div {
      width: 170px;
      height: 80px;

      img {
        width: 70px;
        height: 70px;
        margin-bottom: 10px;
      }
    }
  }
}
.new-map {
  width: 100%;
  background: url("../assets/images/home/home15.png");
}
.new-type {
  .new-type-c {
    width: 1800px;
    margin: 0 auto;
    margin-top: 80px;
    .title {
      width: 100%;
      text-align: center;
      font-size: 40px;
      font-weight: bold;
      position: relative;
      margin-bottom: 40px;
      &::after {
        position: absolute;
        content: "";
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 40px;
        height: 2px;
        background-color: #3c3c49;
      }
    }
    .box {
      display: flex;
      height: 690px;

      .content {
        flex: 25%;
        margin: 30px 10px 35px 10px;
        .title-f {
          // visibility: hidden;
          margin: 30px 10px 35px 10px;
          padding-left: 20px;
          margin-left: 10px;
          text-align: left;
          font-size: 36px;
          color: #fff;

          // &:hover {
          //   // display: block;
          //   // visibility: visible;
          // }
        }
        &:nth-of-type(1) {
          // flex-grow: 1;
          // flex-shrink: 0.8;
          // flex-basis: 0.8;
          background: url("../assets/images/home/home10.png");
          background-size: cover;
        }
        &:nth-of-type(2) {
          // background: pink;
          background: url("../assets/images/home/home09.png");
          background-size: cover;
        }
        &:nth-of-type(3) {
          // background: pink;
          background: url("../assets/images/home/home08.png");
          background-size: cover;
        }
        &:nth-of-type(4) {
          // background: pink;
          background: url("../assets/images/home/home07.png");
          background-size: cover;
        }
        &:hover {
          flex-grow: 1;
          flex-shrink: 0.8;
          flex-basis: 0.8;
          transform: scale(1.1);
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;

          // background: #0d91fe;
          .visible {
            visibility: visible;
            padding: 20px;
            height: 100%;
            // background: rgba(0, 0, 0, 0.54);

            p {
              visibility: visible;
            }
          }
        }
        // .visible {
        //   visibility: hidden;
        // }

        p {
          visibility: hidden;
          margin-left: 10px;
          text-align: left;
          line-height: 24px;
          font-size: 18px;
          color: #ffffff;
          &:hover {
            // display: block;
            visibility: visible;
          }
        }
      }
    }
  }
}

.industry {
  margin-top: 60px;
  .title {
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    position: relative;
    margin-bottom: 40px;
    &::after {
      position: absolute;
      content: "";
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 2px;
      background-color: #3c3c49;
    }
  }
}

.section-title {
  color: #3c3c49;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 50px;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 2px;
    background-color: #3c3c49;
  }
}
#blog {
  .lates-news {
    margin-bottom: 50px;
    p {
      font-size: 12px !important;
      color: #b4b4b4 !important;
      margin-bottom: 4px;
    }
    h2 {
      font-size: 20px;
      color: #535353;
      font-weight: bold;
      margin-bottom: 20px;
    }
    & > div {
      color: #797979 !important;
      font-size: 18px !important;
      line-height: 26px;
      margin-bottom: 28px;
    }
    img {
      width: 506px;
      height: 300px;
    }
  }
}
.container-w {
  width: 1800px;
  margin: 0 auto;
}

.news-box-home {
  background-color: #fff;
  padding: 0;
  margin-bottom: 160px;
  cursor: pointer;
  .bottom {
    background-color: #fff;
    padding: 20px;
    // height: 200px;
    padding-bottom: 40px;
    color: #797979;
    font-size: 20px;
    & > div {
      display: -webkit-box;
      overflow: hidden;
      white-space: normal !important;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    p {
      color: #333333;
      font-size: 20px;
    }
  }
  .news-image {
    width: 382px;
    height: 320px;
  }
}
.more {
  margin-top: 0px !important;
  font-size: 20px !important ;
}
</style>
